import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"]

  initialize() {
    this.currentIndex = 0
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.itemTargets.length
    this.updateCarousel()
  }

  previous() {
    this.currentIndex = (this.currentIndex - 1 + this.itemTargets.length) % this.itemTargets.length
    this.updateCarousel()
  }

  updateCarousel() {
    this.itemTargets.forEach((el, index) => {
      el.classList.toggle("hidden", index !== this.currentIndex);
    });
  }
}
