import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let newIndex = event.newIndex + 1

    fetch(`/account_questions/${id}/move?new_position=${newIndex}`, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token"),
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      credentials: "same-origin"
    })
      .then(response => response.json())
      .then(data => {
        console.log(data) // Optional: Log the response data
      })
      .catch(error => {
        console.error(error) // Optional: Handle the error
      })
  }
}

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}