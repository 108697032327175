import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["newQuestionField"];
  
    connect() {
      this.toggleNewQuestionField();
    }
  
    toggleNewQuestionField() {
      const createNewQuestionCheckbox = this.element.querySelector(
        'input[name="account_question[create_new_question]"]'
      );
      const newQuestionField = this.newQuestionFieldTarget;
  
      if (createNewQuestionCheckbox.checked) {
        newQuestionField.style.display = "block";
      } else {
        newQuestionField.style.display = "none";
      }
    }
}