import { Controller } from "@hotwired/stimulus"
import moment from "moment"

export default class extends Controller {
  static targets = ["details", "month", "monthName"]
  static values = { events: Array, url: String }

  connect() {
    this.month = moment()
    this.updateCalendar()
  }

  nextMonth(event) {
    event.preventDefault()
    this.month = this.month.clone().add(1, 'months')
    this.updateCalendar()
    this.updateMonthName() // Update the month name
  }

  previousMonth(event) {
    event.preventDefault()
    this.month = this.month.clone().subtract(1, 'months')
    this.updateCalendar()
    this.updateMonthName() // Update the month name
  }

  updateMonthName() {
    this.monthNameTarget.textContent = this.month.format('MMMM YYYY')
  }

  createDayElement(date) {
    let hasEvent = this.eventsValue.includes(date.format('YYYY-MM-DD'));
    let buttonClass = 'mx-auto flex h-8 w-8 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200';
    if (hasEvent) {
      buttonClass += ' font-semibold text-tertiary-600';
    }

    let button = document.createElement('button');
    button.type = 'button';
    button.className = buttonClass;

    let time = document.createElement('time');
    time.setAttribute('datetime', date.format('YYYY-MM-DD'));
    time.textContent = date.date();

    button.appendChild(time);

    button.addEventListener('click', (event) => {
      // Find the <time> element within the clicked button
      const timeElement = event.target.closest('button').querySelector('time');
      // Get the datetime attribute
      const clickedDate = timeElement.getAttribute('datetime');
      const utcDate = moment.utc(clickedDate, "YYYY-MM-DD");
      this.loadEventsForDate(utcDate);
  });

    let div = document.createElement('div');
    div.className = 'py-2';
    div.appendChild(button);

    return div;
  }

  updateCalendar() {
    // Clear the current calendar
    this.monthTarget.innerHTML = ''

    let date = this.month.clone().startOf('month')

    // Add padding for the first week
    const startDay = date.day() === 0 ? 6 : date.day() - 1; // Adjust for Monday as the first day of the week
    for (let i = 0; i < startDay; i++) {
      let paddingDiv = document.createElement('div')
      paddingDiv.className = 'py-2'
      this.monthTarget.appendChild(paddingDiv)
    }

    // Generate days for the month
    while (date.month() === this.month.month()) {
      this.monthTarget.appendChild(this.createDayElement(date))
      date.add(1, 'days')
    }
  }

  async loadEventsForDate(dateString) {
    try {
      const response = await fetch(this.urlValue);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const allEvents = await response.json();

      // Ensure selectedDate is correctly parsed
      const selectedDate = moment(dateString, 'YYYY-MM-DD'); // Adjust format and timezone as necessary
      console.log(selectedDate)

      const filteredEvents = allEvents.filter(event => {
        const eventDate = moment(event.start_time)
        return eventDate.isSame(selectedDate, 'day');
      });

      this.updateEventsSection(filteredEvents);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  }


  updateEventsSection(events) {
    // Select the HTML element that contains the events
    const eventsSection = document.querySelector('.events-section');
    // Clear existing content
    eventsSection.innerHTML = '';

    // Populate with new events data
    events.forEach(event => {
      // Create a function to format the date and time in UTC
      function formatUTCDate(dateString, options) {
        return new Intl.DateTimeFormat('en-US', { ...options, timeZone: 'UTC' }).format(new Date(dateString));
      }

      // Define options for date and time formatting
      const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
      const timeOptions = { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: 'UTC' };

      // Format start and end times
      const startDateFormatted = formatUTCDate(event.start_time, dateOptions);
      const startTimeFormatted = formatUTCDate(event.start_time, timeOptions);
      const endTimeFormatted = formatUTCDate(event.end_time, timeOptions);

      // Since the event spans exactly one day from 12:00 AM to 11:59 PM UTC,
      // we directly use the formatted start date and concatenate the formatted times.
      const dateTimeString = `${startDateFormatted} ${startTimeFormatted} - ${endTimeFormatted}`;
      const eventTypeClass = event.event_type === "Training/HR" ? "training-hr" : event.event_type.toLowerCase().replace(/\//g, '-');

      // Construct the event HTML string
      eventsSection.innerHTML += `
        <div class="p-2 mt-2 border-t bg-white rounded-lg shadow-sm border-gray-200">
          <p class="leading-6 font-medium text-gray-900">${event.name}
            <span class="rounded-full px-2 py-1 text-white text-xs bg-color-${eventTypeClass}">
              ${event.event_type}
            </span>
          </p>
          <div class="mt-1 mx-4 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <p>${dateTimeString}</p>
          </div>
        </div>
      `;
    });

  }


}
