import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["banner"]

  dismiss() {
    this.bannerTarget.style.display = 'none';

    fetch('/dismiss_banner', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ banner_dismissed: true })
    });
  }
}
