// app/javascript/controllers/confirm_submission_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { message: String }

  confirmSubmission(event) {
    if (!confirm(this.messageValue)) {
      event.preventDefault();
    }
  }
}
